import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import emailIcon from '../static/imgs/emailIcon.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button, Modal, IconButton, Typography, Divider, Backdrop, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import Alert from '@material-ui/lab/Alert';
import { Cookies } from 'react-cookie';
import { makeStyles } from '@material-ui/core/styles';

let EmailTitle;
let EmailText;
let CodeTitle;
let CodeText;
let RemainingTime;
let NoReceiveCode;
let Resend;
let Verify;
let Back;
let Cancel;
let InvalidCode;
let Success;
let Verified;
let Ok;
let Retry;
let TimerExpired;
let retryText;
let codeSentMsg;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    position: 'absolute',
    background: 'white',
    width: 440,
    height: 470,
    boxShadow: theme.shadows[5],
  },
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    height: '150px',
  };
}

export function VerifyEmailId(props) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState();
  const [error, setError] = useState(false);
  const http = Http.getInstance();
  const [over, setOver] = useState(false);
  const [[m, s], setTime] = useState([Number(localStorage.getItem("m")), Number(localStorage.getItem("s"))]);
  const [openAlert, setOpenAlert] = useState(false);
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  var cookies = new Cookies();
  const authorised_user = cookies.get('authorised_user');
  const dataSource = dataSources();
  const default_mfa_type =  JSON.parse(localStorage.getItem("selectedMFAMethod"));
  const [translations, setTranslations] = useState({
    EmailTitle: 'Multifactor Authentication setup for email verification',
    EmailText: 'Adding email and phone number authentication ensure that only authorized individuals can access information',
    CodeTitle: 'Enter user authentication code sent to your email',
    CodeText: 'Enter the 6 digit code to add and verify email',
    RemainingTime: 'Remaining time:',
    NoReceiveCode: "didn't receive code?",
    Resend: 'Re-send',
    Verify: 'Verify',
    Back: 'BACK',
    Cancel: 'CANCEL',
    InvalidCode: 'Invalid user authentication code. Please retry!',
    Success: 'SUCCESS',
    Verified: 'Your Email is successfully verified!',
    Ok: 'Ok',
    Retry: 'RETRY',
    TimerExpired: 'Timer has expired',
    retryText: 'Please click the button to retry',
    codeSentMsg: 'Authentication code has been sent'
  });
    // (props.location.default_mfa_type !== undefined || props.location.default_mfa_type !== null) &&
    // props.location.default_mfa_type;

    useEffect(()=>{
      const translationData = JSON.parse(localStorage.getItem('translations') || '{}');
      if (translationData) {
        setTranslations({
          EmailTitle: translationData['portal-email_title'] || 'Multifactor Authentication setup for email verification',
          EmailText: translationData['portal-email_text'] || 'Adding email and phone number authentication ensure that only authorized individuals can access information',
          CodeTitle: translationData['portal-email_enter_code_activate_title'] || 'Enter user authentication code sent to your email',
          CodeText: translationData['portal-email_enter_code_activate_text'] || 'Enter the 6 digit code to add and verify email',
          RemainingTime: translationData['portal-email_enter_code_remaining_time'] || 'Remaining time:',
          NoReceiveCode: translationData['portal-email_enter_code_didnt_recieve_code'] || "didn't receive code?",
          Resend: translationData['portal-email_enter_code_resend'] || 'Re-send',
          Verify: translationData['portal-email_verify_button'] || 'Verify',
          Back: translationData['portal-email_back_button'] || 'BACK',
          Cancel: translationData['portal-email_cancel_button'] || 'CANCEL',
          InvalidCode: translationData['portal-email_enter_code_error_text'] || 'Invalid user authentication code. Please retry!',
          Success: translationData['portal-email_enter_code_activation_success_message_title'] || 'SUCCESS',
          Verified: translationData['portal-email_enter_code_activation_success_message_text'] || 'Your Email is successfully verified!',
          Ok: translationData['portal-email_enter_code_activation_success_message_ok'] || 'Ok',
          Retry: translationData['portal-mfa_retry_button'] || 'RETRY',
          TimerExpired: translationData['portal-mfa_timer_expired_msg'] || 'Timer has expired',
          retryText: translationData['portal-mfa_retry_text'] || 'Please click the button to retry',
          codeSentMsg: translationData['portal-mfa_code_sent_msg'] || "Authentication code has been sent"
      })
      }
    },[])

  useEffect(() => {
    var data = { type: 'email' };
    const OTPReload = localStorage.getItem("OTPReload");
    localStorage.setItem('m',m);
    localStorage.setItem('s',s);
    if (authorised_user === 'true' && OTPReload === "true") {
      localStorage.setItem('OTPReload',false);
      http
        .postData(`${dataSource.sendOtp}`, data, '')
        .then(res => {
          console.log('res', res);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  }, [m,s]);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    //if (over) return;
    //if (m === 0 && s === 0) setOver(true);
    if( m === 9 && s === 0) {
      if(localStorage.getItem("sent") == "true") { 
        localStorage.setItem("retry",false);
      } 
      else{
         localStorage.setItem("retry",true);
      }
    }
    if (m === 0 && s === 0) {setOver(true); localStorage.setItem("retry",false); localStorage.setItem("sent",false);}
    else if (m === 0 && s === 0) {
      setTime([59, 59]);
    } else if (s == 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  };

  const reset = () => {
    localStorage.setItem('OTPReload',true);
    setTime([parseInt(10), parseInt(0)]);
    const OTPReload = localStorage.getItem('OTPReload');
    var data = { type: 'email' };
    if(OTPReload === 'true'){
      localStorage.setItem('OTPReload',false);
      localStorage.setItem("sent",true);
      localStorage.setItem('retry',false);
    http
      .postData(`${dataSource.sendOtp}`, data, '')
      .then(res => {
        console.log('res', res);
      })
      .catch(error => {
        console.log(error, 'error');
      });
    }
  };

  const handleCode = event => {
    if (event.target.value.length === 0) {
      setError(false);
    }
    setCode(event.target.value);
  };

  const handleClose = () => {
    setOpenAlert(false);
    window.location.href = '/myaccount/mfa/settings';
  };

  const verifyCode = () => {
    setLoading(true);
    let otp = { OTP: code.trim(), type: 'email' };
    http
      .postData(`${dataSource.validateOtp}`, otp, '')
      .then(res => {
        if (res.status === 200) {
          if (res.data.valid === true) {
            // setOpenAlert(true);
            setError(false);
            const user = {
              mfa_enabled: true,
              email_verified: true,
              default_mfa_type: default_mfa_type,
            };
            http
              .patchData(`${dataSource.users}`, user, '')
              .then(res => {
                if (res.status === 200) {
                  cookies.set('mfa_enabled', true, {
                    path: '/',
                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                  });
                  cookies.set('default_mfa_type', default_mfa_type, {
                    path: '/',
                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                  });
                  cookies.set('email_verified', true, {
                    path: '/',
                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                  });
                  http.postData(`${dataSource.mfaMethods}/${default_mfa_type.mfa_id}`, '', '')
                  .then(res => {
                    console.log("res",res);
                    setLoading(false);
                    setOpenAlert(true);
                  }).catch(error => {
                    console.log("error", error);
                    setLoading(false);
                  });
                  // setTimeout(()=>{window.location.href="/launcher"},3000);
                }
              })
              .catch(error => {
                console.log(error);
                setLoading(false);
              });
          } else if (res.data.valid === false) {
            console.log('invalid otp');
            setLoading(false);
            setOpenAlert(false);
            setError(true);
          }
        } else {
          setLoading(false);
          setOpenAlert(false);
          setError(true);
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  if (authorised_user === 'true') {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
      window.location.href = "/myaccount/mfa/settings";
    }
    return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ThemeProvider theme={theme}>
          <div dir="ltr">
            <AppLayout
              apps={JSON.parse(localStorage.getItem('apps'))}
              errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
              errorResponse={localStorage.getItem('error')}
            />

            {/* { verified && <Alert 
                                icon={false} 
                                className="successNotification"
                                onClose={() => {setVerified(false)}}>
                                  Your Email is successfully verified!
                                </Alert> 
                  } */}

            <Modal
              open={openAlert}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div style={modalStyle} className={classes.modal}>
                <div className={'dialogHeader'}>
                  <Typography color="textPrimary" className={'dialogTitle'}>
                    {translations.Success}
                  </Typography>
                  <IconButton onClick={handleClose} className={'closeButton'}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <Divider />
                <div className={'successMsg'}>
                  {translations.Verified}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClose();
                  }}
                  className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn">
                  {translations.Ok}
                </Button>
              </div>
            </Modal>

            <div className="totpRoot">
              <h5 className="totpTitle">
                {translations.EmailTitle}
              </h5>

              <div className="totp">
                <div className="emailSection">
                  <img alt="user" className="userLogo" height="95px" src={userLogo} />
                  <div>
                    {translations.EmailText}
                  </div>
                </div>

                <div className="otpVerifySection">
                  <img alt="email" className="emailUserLogo" src={emailIcon} />
                  {m==0 && s==0 ? 
                                <h5 className="timeoutMsg">
                                  <h4>{translations.TimerExpired}</h4>
                                  <br></br>
                                  {/* <button 
                                  className="resendBtn" 
                                  onClick={() => {window.location.href = '/myaccount/mfa/settings';}}>
                                    Click Here
                                  </button> */}
                                  {translations.retryText}
                                </h5> : 
                  <div>
                    <h5>
                      {translations.CodeTitle}
                    </h5>
                    <div>
                      {translations.CodeText}
                    </div>

                    <TextField
                      className="otpField"
                      variant="outlined"
                      fullWidth
                      value={code}
                      required
                      onChange={e => handleCode(e)}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          verifyCode();
                        }
                      }}
                    />

                    {error && (
                      <div className="otpErrorMsg">
                        {translations.InvalidCode}
                      </div>
                    )}

                    <div className="timer">
                      <div>
                        {translations.RemainingTime}&nbsp;
                        {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
                      </div>
                      {localStorage.getItem("retry")=="true" ? <div>
                        {translations.NoReceiveCode}
                        <button className="resendBtn" onClick={() => reset()}>
                          {translations.Resend}
                        </button>
                      </div> : (localStorage.getItem("sent") == "true") && <div className="codeSentMsg">{translations.codeSentMsg}</div>}
                    </div>

                    {/* <div className="timer">
                      <div>
                        {translations.RemainingTime}
                        {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
                      </div>
                      <div>
                        {translations.NoReceiveCode}
                        <button className="resendBtn" onClick={() => reset()}>
                          {translations.Resend}
                        </button>
                      </div>
                    </div> */}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        verifyCode();
                      }}
                      disabled={!s || code === undefined || code === null}
                      className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn emailVerifyBtn">
                      {translations.Verify}
                    </Button>
                  </div>
                  }

                  {m==0 && s==0 ? 
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.location.href = '/myaccount/mfa/settings';
                      }}
                      className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn emailVerifyBtn">
                      {translations.Retry}
                    </Button> : 
                    <div className="navigateParent">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.location.href = '/myaccount/mfa/email';
                        }}
                        className="classes.button MuiButton-fullWidth button-focus-disable navigates">
                        {translations.Back}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.location.href = '/myaccount/mfa/settings';
                        }}
                        id="cancel"
                        className="classes.button MuiButton-fullWidth button-focus-disable navigates">
                        {translations.Cancel}
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </div>

            <FooterMenu />
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  } else {
    window.location.href = '/';
  }
}
