import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import emailIcon from '../static/imgs/emailIcon.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

export function Email(props) {
  const classes = useStyles();
  const history = useHistory();
  const cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const mfaEmail =
    cookies.get('mfa_email') === 'null' || cookies.get('mfa_email') === 'undefined'
      ? ''
      : cookies.get('mfa_email');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(mfaEmail);
  const [error, setError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [translations, setTranslations] = useState({
    EmailTitle: 'Multifactor Authentication setup for email verification',
    EmailText: 'Adding email authentication ensure that only authorized individuals can access information',
    AddEmailTitle: 'Add your email address',
    AddEmailText: 'Enter your email id to which authentication code has to be sent',
    ErrorEmailText: 'Enter a valid email',
    AddEmailButton: 'Add Email',
    emptyEmailError: 'Please enter email id',
    BackButton: 'BACK',
    emailDisclaimer: '(Entered email id will be used only for security purpose)'
});

useEffect(()=>{
  const translationData = JSON.parse(localStorage.getItem('translations') || '{}');
  if (translationData) {
    setTranslations({EmailTitle: translationData['portal-email_title'] || 'Multifactor Authentication setup for email verification',
    EmailText: translationData['portal-email_text'] || 'Adding email authentication ensure that only authorized individuals can access information',
    AddEmailTitle: translationData['portal-email_add_email_title'] || 'Add your email address',
    AddEmailText: translationData['portal-email_add_email_text'] || 'Enter your email id to which authentication code has to be sent',
    ErrorEmailText: translationData['portal-email_add_email_error_text'] || 'Enter a valid email',
    AddEmailButton: translationData['portal-email_add_email_button'] || 'Add Email',
    emptyEmailError: translationData['portal-email_add_empty_email_error'] || 'Please enter email id',
    BackButton: translationData['portal-email_add_email_back_button'] || 'BACK',
    emailDisclaimer: translationData['portal-email_security_disclaimer_text'] || '(Entered email id will be used only for security purpose)'
  })
  }
},[])

  const handleEmail = event => {
    if (event.target.value.length === 0) {
      setError(false);
    }
    setEmptyError(false);
    setEmail(event.target.value);
  };

  let EmailTitle;
  let EmailText;
  let AddEmailTitle;
  let AddEmailText;
  let ErrorEmailText;
  let emptyEmailError;
  let AddEmailButton;
  let BackButton;
  let emailDisclaimer;

  const addEmail = () => {
    if(email.length === 0){
      setEmptyError(true);
    }
    else{
    setLoading(true);
    let regex = new RegExp('^[^._]+[a-zA-Z0-9._-]+[a-zA-Z0-9]+@[^._]+[a-zA-Z0-9._-]+.[a-zA-Z]{2}$');
    let isValid = regex.test(email.trim());
    if (isValid) {
      const user = {
        email: email.trim(),
      };
      http
        .patchData(`${dataSource.users}`, user, '')
        .then(res => {
          if (res.status === 200) {
            console.log('user mfa updated', res);
            setError(false);
            cookies.set('mfa_email', email.trim(), {
              path: '/',
              domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
            });
            //window.location.href="/verifyEmailId";
            localStorage.setItem("OTPReload",true);
            localStorage.setItem("sent",false);
            localStorage.setItem("retry",false);
            localStorage.setItem("m","10");
            localStorage.setItem("s","0");
            history.push({
              pathname: '/myaccount/mfa/verifyEmailId'
              // default_mfa_type:
              //   (props.location.default_mfa_type !== undefined || props.location.default_mfa_type !== null) &&
              //   props.location.default_mfa_type,
            });
            setLoading(false);
          }
          else{
            setError(true);
            setLoading(false);
          }
        })
        .catch(error => {
          console.log("error",error);
          setLoading(false);
        });
    } else {
      setError(true);
      setLoading(false);
    }
  }
  };

  const authorised_user = cookies.get('authorised_user');
  if (authorised_user === 'true') {
    return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ThemeProvider theme={theme}>
          <div dir="ltr">
            <AppLayout
              apps={JSON.parse(localStorage.getItem('apps'))}
              errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
              errorResponse={localStorage.getItem('error')}
            />

            <div className="totpRoot">
              <h5 className="totpTitle">
                {translations.EmailTitle}
              </h5>

              <div className="totp">
                <div className="emailSection">
                  <img alt="user" className="userLogo" height="95px" src={userLogo} />
                  <div>
                    {translations.EmailText}
                  </div>
                </div>

                <div className="otpVerifySection">
                  <img alt="email" className="emailUserLogo" src={emailIcon} />
                  <h5>{translations.AddEmailTitle}</h5>
                  <div>
                    <div>{translations.AddEmailText}
                    </div>
                    <div>{translations.emailDisclaimer}
                    </div>
                  </div>

                  <TextField
                    className="otpField"
                    variant="outlined"
                    fullWidth
                    value={email}
                    required
                    onChange={e => handleEmail(e)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        addEmail();
                      }
                    }}
                  />

                  {error && (
                    <div className="otpErrorMsg">
                      {translations.ErrorEmailText}
                    </div>
                  )}

                  {emptyError && (
                    <div className="otpErrorMsg">
                      {translations.emptyEmailError}
                    </div>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addEmail();
                    }}
                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn">
                    {translations.AddEmailButton}
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.location.href = '/myaccount/mfa/settings';
                    }}
                    id="cancel"
                    className="classes.button MuiButton-fullWidth button-focus-disable navigateMethod">
                    {translations.BackButton}
                  </Button>
                </div>
              </div>
            </div>

            <FooterMenu />
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  } else {
    window.location.href = '/';
  }
}
