import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, Grid } from '@material-ui/core';
import { BrowserRouter as Link } from 'react-router-dom';
import {NavLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(10),
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
    backgroundColor: 'black',
  },
}));

export function TileLayout(props) {
  const classes = useStyles();

  const translationsData = JSON.parse(sessionStorage.getItem('translations'));
  let noAppsAssignedText = props.noAppsAssignedValue;
  function FormRow() {
    let appId = process.env.REACT_APP_BNIPortalId;
    let assignedApps = props.apps;
    const allApps = assignedApps.filter(apps => apps._id !== appId);

    let apps = Object.entries(allApps);

    return (
      <React.Fragment>
        {Object.keys(apps).map((key, index) => (
          <Grid item xs={2} key={index}>
            {apps[key][1]['tos_acceptance_status'] ? (
              <a href={apps[key][1]['url']} rel="noopener noreferrer">
                <Card>
                  <Typography className={classes.paper}></Typography>
                  <img
                    className="img-center"
                    src={apps[key][1]['logo_url']}
                    height="75"
                    alt={apps[key][1]['app_name']}
                  />
                  <Typography className={classes.paper}></Typography>
                </Card>
              </a>
            ) : (
              <NavLink
                to={{
                  pathname: '/terms',
                  state: { appId: apps[key][1]['_id'] },
                }}
                rel="noopener noreferrer">
                <Card>
                  <Typography className={classes.paper}></Typography>
                  <img
                    className="img-center"
                    src={apps[key][1]['logo_url']}
                    height="75"
                    alt={apps[key][1]['app_name']}
                  />
                  <Typography className={classes.paper}></Typography>
                </Card>
              </NavLink>
            )}
          </Grid>
        ))}
      </React.Fragment>
    );
  }
  if (Object.keys(props.apps).length > 1) {
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <FormRow />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  } else if (Object.keys(props.apps).length === 1) {
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={2}>
                <Card>
                  <Typography className={classes.paper}></Typography>

                  <Typography className={classes.paper}>{noAppsAssignedText}</Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  } else {
    return <div>&nbsp;</div>;
  }
}
