import React, { Component } from 'react';
import Http from '../../services/http';
// import HttpTranslations from '../../services/http-translation';
import { Redirect } from 'react-router-dom';
import { dataSources } from '../../utils/data-sources';
import { LoginLayout } from '../../components/login-layout/login-layout-new';
import logo from '../../static/imgs/BNIportal_logo_RGB_vertical.png';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import {
    Grid,
    Typography,
    IconButton,
    Button,
    InputAdornment,
    TextField,
    Select,
    Paper,
    MenuItem,
} from '@material-ui/core';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import ContainedButtons from '../../components/loading-button/loading-button';
import { staticTranslationData } from '../../utils/static-translation-text';
import * as staticTextKey from '../../utils/translation-variable';
import * as Sentry from '@sentry/browser';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export class LoginPage extends Component {
    dataSources = dataSources();
    http = Http.getInstance();
    state = {
        username: '',
        password: '',
        error: false,
        errorField: false,
        ready: false,
        password_error_text: false,
        username_error_text: false,
        loginFlag: false,
        selectedLanguageOption: '',
        optionsLanguage: [],
        translationFlag: false,
        loginValue: 'LOGIN',
        userNameValue: '',
        passwordValue: '',
        forgotPasswordValue: 'Forgot Password?',
        showPassword: false,
        mfa_enabled: false,
        default_mfa_type: {}
    };

    async componentDidMount() {
        this.setState({ ready: true });
        console.log(process.env.REACT_APP_myBNIversion);
        console.log('Title ' + process.env.REACT_APP_title);
        console.log('Description' + process.env.REACT_APP_description);
        await this.loadLanguageData();
        await this.loadTranslationData();
    }

    loadLanguageData() {
        const cookies = new Cookies();
        var selectedLanguageCookie = cookies.get('bni_language_select');

        let i18nurl = `${this.dataSources.languages}`;

        console.log(staticTextKey.DEFAULT_ENGLISH_TRANSLATION.iso_id);
        axios({
            method: 'get',
            url: i18nurl,
            headers: {
                client_name: `${process.env.REACT_APP_clientName}`,
                client_secret: `${process.env.REACT_APP_clientSecret}`,
                'x-iso-ids-only': 'N',
            },
        })
            .then(res => {
                const data = res.data;

                data.forEach(element => {
                    element.value = element.iso_id;
                });

                this.setState({
                    optionsLanguage: data,
                    selectedLanguageOption:
                        selectedLanguageCookie !== undefined
                            ? selectedLanguageCookie
                            : staticTextKey.DEFAULT_ENGLISH_TRANSLATION.iso_id,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    loadTranslationData(languageValue) {
        var language = window.navigator.userLanguage || window.navigator.language;
        console.log(language);
        language = 'en-US';
        const cookies = new Cookies();
        var selectedLanguageCookie = cookies.get('bni_language_select');
        console.log(selectedLanguageCookie);
        language = selectedLanguageCookie !== undefined ? selectedLanguageCookie : language;

        let lang = languageValue !== undefined ? languageValue : language;

        // let translationsBody = {
        //   lang: languageValue !== undefined ? languageValue : language,
        //   keys: staticTranslationData,
        // };

        axios({
            method: 'post',
            url: `${this.dataSources.translateApi}/${lang}/search`,
            data: {
                has_translation: true,
                group_code: 'portal.login',
                page_size: 100,
            },
            headers: {
                'Content-Type': 'application/json',
                client_name: `${process.env.REACT_APP_clientName}`,
                client_secret: `${process.env.REACT_APP_clientSecret}`,
                'x-iso-ids-only': 'N',
            },
        })
            // axios({
            //   method: 'post',
            //   url: `${this.dataSources.translationSearch}`,
            //   data: translationsBody,
            //   headers: { 'X-API-KEY': `${process.env.REACT_APP_BNITranslationKey}` },
            // })
            .then(response => {
                const translationRawData = response.data.translations;

                let portaltranslation = {};
                translationRawData.map(function (row) {
                    portaltranslation[row.lang_key] =
                        row.lang_value !== undefined ? row.lang_value : row.en_val;
                });

                sessionStorage.setItem('lang', JSON.stringify(lang));
                sessionStorage.setItem('translations', JSON.stringify(portaltranslation));

                this.setState({
                    userNameValue: portaltranslation[staticTextKey.userNameValue],
                    passwordValue: portaltranslation[staticTextKey.passwordValue],
                    forgotPasswordValue: portaltranslation[staticTextKey.forgotPasswordValue],
                    loginValue: portaltranslation[staticTextKey.loginValue],
                });
            })
            .catch(function (response) {
                console.log(response);
            });
           
            axios({
                method: 'post',
                url: `${this.dataSources.translateApi}/${lang}/search`,
                data: {
                  has_translation: true,
                  group_code: 'portal-mfa',
                  page_size: 150,
                },
                headers: {
                  'Content-Type': 'application/json',
                  client_name: `${process.env.REACT_APP_clientName}`,
                  client_secret: `${process.env.REACT_APP_clientSecret}`,
                  'x-iso-ids-only': 'N',
                },
              }).then(response => {
                const translationRawData = response.data.translations;
                let portalMFAtranslation = {};
                translationRawData.map(function (row) {
                  portalMFAtranslation[row.lang_key] = row.lang_value ? row.lang_value : row.en_val;
                });
                localStorage.setItem('lang', JSON.stringify(lang));
                localStorage.setItem('VerifyTranslations', JSON.stringify(portalMFAtranslation));
              })
              .catch(function (response) {
                console.log(response);
            });
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            error: true,
            errorField: false,
            password_error_text: false,
            username_error_text: false,
            errors: '',
        });
    };

    languageChange = event => {
        const cookies = new Cookies();
        this.setState({
            selectedLanguageOption: event.target.value,
            translationFlag: true,
        });
        var date = new Date();
        date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
        cookies.set('bni_language_select', event.target.value, {
            path: '/',
            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
            expires: date,
        });
        this.loadTranslationData(event.target.value);
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    // login button function
    handleSubmit = event => {
        event.preventDefault();
        const cookies = new Cookies();
        event.preventDefault();
        let loginData = {
            password: this.state.password,
            username: this.state.username,
        };

        if (this.state.password === '') {
            this.setState({
                password_error_text: true,
            });
        }

        if (this.state.username === '') {
            this.setState({
                username_error_text: true,
            });
        }
        if (this.state.password !== '' && this.state.username !== '') {
            this.setState({
                loginFlag: true,
            });

            let url = `${this.dataSources.auth}/login`;
            return this.http
                .postLoginData(url, loginData)
                .then(res => {
                    if (res.status === 200) {
                        //get access, refresh token and IAM id from response
                        let access_token = res.data.access_token;
                        let refresh_token = res.data.refresh_token;
                        let IAM_id = res.data.IAM_id;
                        let translation_token = res.data.translation_token;

                        // get name, locale from user detail field in response
                        let first_name = res.data.user_detail[0].first_name;
                        let last_name = res.data.user_detail[0].last_name;
                        let locale = res.data.user_detail[0].locale;
                        let language = window.navigator.userLanguage || window.navigator.language;
                        let name = first_name + ' ' + last_name;
                        let mfa_enabled = res.data.user_detail[0].mfa_enabled;
                        let default_mfa_type = res.data.user_detail[0].default_mfa_type;
                        let totp_verified = res.data.user_detail[0].totp_verified;
                        let email_verified = res.data.user_detail[0].email_verified;
                        let mobile_number_verified = res.data.user_detail[0].mobile_number_verified;
                        let mfa_email = res.data.user_detail[0].mfa_email;
                        let mobile_number = res.data.user_detail[0].mobile_number;
                        
                        // set required cookies to be used in cross domains
                        cookies.set('access_token', access_token, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('refresh_token', refresh_token, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('IAM_id', IAM_id, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('translation_token', translation_token, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('name', name, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('locale', locale, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('browser_locale', language, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('mfa_enabled', mfa_enabled, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('default_mfa_type', default_mfa_type, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('totp_verified', totp_verified, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('email_verified', email_verified, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('mobile_number_verified', mobile_number_verified, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('mfa_email', mfa_email, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('mobile_number', mobile_number, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });
                        cookies.set('loggedIn', true, {
                            path: '/',
                            domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                        });

                        if(mfa_enabled === false){
                            cookies.set('authorised_user', true, {
                                path: '/',
                                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                            });
                        }

                        //get launcher app id from env file
                        // get all apps assigned
                        // get active Tos id of launcher app
                        let launcherDataId = process.env.REACT_APP_BNIPortalId;
                        let apps = res.data.user_detail[0].apps;
                        this.setState({
                            error: false,
                        });
                        if (apps === null) {
                            let translationData = JSON.parse(sessionStorage.getItem('translations'));
                            this.setState(state => ({
                                errors: translationData[staticTextKey.noAppsAssignedValue],
                                error: true,
                                errorField: true,
                                password_error_text: true,
                                username_error_text: true,
                                loginFlag: false,
                            }));
                        }
                        let launcherDataTosId = apps.find(x => x._id === launcherDataId)?.active_tos_ids?._id;

                        let launcherTerms;

                        if (launcherDataTosId === undefined) {
                            let translationData = JSON.parse(sessionStorage.getItem('translations'));
                            this.setState(state => ({
                                errors: translationData[staticTextKey.noAppsAssignedValue],
                                error: true,
                                errorField: true,
                                password_error_text: true,
                                username_error_text: true,
                                loginFlag: false,
                            }));
                        }
                        // accepted tos Id
                        let acceptedTosId = res.data.user_detail[0].accepted_tos;
                        if (!this.state.error) {
                            if (acceptedTosId == null) {
                                launcherTerms = false;
                            } else {
                                // check accepted Tos id is same as active tos id.
                                let acceptedTosIdArray = acceptedTosId.map(a => a._id);
                                let activeTosStatus = acceptedTosIdArray.includes(launcherDataTosId);
                                launcherTerms = activeTosStatus;
                            }

                            this.setState({
                                error: false,
                                errorField: false,
                                password_error_text: false,
                                username_error_text: false,
                                errors: '',
                                launcherAppId: launcherDataId,
                                launcherTosId: launcherDataTosId,
                                launcherTermsAccepted: launcherTerms,
                            });
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        let translationData = JSON.parse(sessionStorage.getItem('translations'));
                        this.setState({
                            errors: error.response.data.msg,
                            error: true,
                            errorField: true,
                            password_error_text: true,
                            username_error_text: true,
                            loginFlag: false,
                        });

                        return Sentry.captureException(error.response.data.msg);
                    } else if (error.response.status === 500) {
                        this.setState(state => ({
                            errors: error.response.data.msg,
                            error: true,
                            errorField: true,
                            password_error_text: true,
                            username_error_text: true,
                            loginFlag: false,
                        }));

                        return Sentry.captureException(error.response.data.msg);
                    } else {
                        this.setState(state => ({
                            errors: error.response.data.msg,
                            error: true,
                            errorField: true,
                            password_error_text: true,
                            username_error_text: true,
                            loginFlag: false,
                        }));
                        console.log('try again');
                    }
                });
        }
    };

    dynamicSort = property => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            if (sortOrder == -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        }
    }

    render() {
        let loginText = this.state.loginValue;
        let userText = this.state.userNameValue;
        let passwordText = this.state.passwordValue;
        let forgotPasswordText = this.state.forgotPasswordValue;
        const { selectedLanguageOption, optionsLanguage } = this.state;
        const cookies = new Cookies();
        let sortedLanguages = optionsLanguage;
        localStorage.setItem("OTPReload",true);
        localStorage.setItem("sent",false);
        localStorage.setItem("retry",false);
        localStorage.setItem("m","10");
        localStorage.setItem("s","0");

        sortedLanguages.sort(this.dynamicSort("language"));

        if (this.state.launcherTermsAccepted === false) {
            return (
                <Redirect
                    to={{
                        pathname: '/terms',
                        state: {
                            access_token: this.state.access_token,
                            refresh_token: this.state.access_token,
                            IAM_id: this.state.IAM_id,
                            appId: this.state.launcherAppId,
                        },
                    }}
                />
            );
        } else if ((this.state.launcherTermsAccepted === true) && (cookies.get('mfa_enabled')=="false")) {
            // return (
            //     <Redirect
            //         to={{
            //             pathname: '/launcher',
            //             state: {
            //                 termsAccepted: this.state.launcherTermsAccepted,
            //                 access_token: this.state.access_token,
            //                 refresh_token: this.state.refresh_token,
            //                 IAM_id: this.state.IAM_id,
            //             },
            //         }}
            //     />
            // );
            window.location.href = "/launcher"
        }
        else if((this.state.launcherTermsAccepted === true) && (cookies.get('mfa_enabled')=="true") && (cookies.get('default_mfa_type').type.toLowerCase()=="totp") ) {
            // return (
            //     <Redirect
            //         to={{
            //             pathname: '/mfa/verification',
            //             state: {
            //                 termsAccepted: this.state.launcherTermsAccepted,
            //                 access_token: this.state.access_token,
            //                 refresh_token: this.state.refresh_token,
            //                 IAM_id: this.state.IAM_id,
            //             },
            //         }}
            //     />
            // );
            window.location.href = '/mfa/verification'
        }
        else if((this.state.launcherTermsAccepted === true) && (cookies.get('mfa_enabled')=="true") && (cookies.get('default_mfa_type').type.toLowerCase()=="email") ) {
            // return (
            //     <Redirect
            //         to={{
            //             pathname: '/mfa/emailVerification',
            //             state: {
            //                 termsAccepted: this.state.launcherTermsAccepted,
            //                 access_token: this.state.access_token,
            //                 refresh_token: this.state.refresh_token,
            //                 IAM_id: this.state.IAM_id,
            //             },
            //         }}
            //     />
            // );
            window.location.href = '/mfa/emailVerification'
        }
        else if((this.state.launcherTermsAccepted === true) && (cookies.get('mfa_enabled')=="true") && (cookies.get('default_mfa_type').type.toLowerCase()=="sms") ) {
            // return (
            //     <Redirect
            //         to={{
            //             pathname: '/mfa/smsVerification',
            //             state: {
            //                 termsAccepted: this.state.launcherTermsAccepted,
            //                 access_token: this.state.access_token,
            //                 refresh_token: this.state.refresh_token,
            //                 IAM_id: this.state.IAM_id,
            //             },
            //         }}
            //     />
            // );
            window.location.href = '/mfa/smsVerification'
        }
        if (this.state.ready) {
            return (
                <React.Fragment>
                    <LoginLayout title="Login">
                        <Paper elevation={3}>
                            <div className="container-wrap">
                                <form noValidate>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12}>
                                            <div className=" form-text-color">
                                                <img className="img-center" src={logo} alt="BNI" height="120px" />
                                            </div>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Typography
                                                className="terms-align-center login-top-padding login-bottom-padding"
                                                color="textSecondary">
                                                Changing the Way the World Does Business®
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <div className="form-group">
                                                <TextField
                                                    id="username"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={this.state.username_error_text}
                                                    placeholder={this.state.translationFlag === true ? userText : userText}
                                                    value={this.state.username}
                                                    required
                                                    onChange={this.handleChange('username')}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PersonIcon className="form-text-color" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <div className="form-group">
                                                <TextField
                                                    id="password"
                                                    variant="outlined"
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    placeholder={
                                                        this.state.translationFlag === true ? passwordText : passwordText
                                                    }
                                                    value={this.state.password}
                                                    error={this.state.password_error_text}
                                                    helperText={this.state.errors}
                                                    fullWidth
                                                    required
                                                    onChange={this.handleChange('password')}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockIcon className="form-text-color" />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    id="passwordVisibility"
                                                                    className="button-focus-disable"
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleClickShowPassword}>
                                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item sm={6}></Grid>
                                        <Grid item sm={6}>
                                            <div className="form-group MuiTypography-colorTextSecondary">
                                                <a
                                                    className="link-style"
                                                    href="https://www.bniconnectglobal.com/web/open/forgetPassword?concept=connect"
                                                    rel="noopener noreferrer">
                                                    <Typography className=" MuiTypography-alignRight">
                                                        {this.state.translationFlag === true
                                                            ? forgotPasswordText
                                                            : forgotPasswordText}
                                                    </Typography>
                                                </a>
                                            </div>
                                        </Grid>
                                        <Grid item sm={12}>
                                            <div className="form-align-center">
                                                {this.state.loginFlag === true ? (
                                                    <ContainedButtons text={loginText} />
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className="bni-background-color MuiButton-fullWidth button-focus-disable"
                                                        type="submit"
                                                        size="large"
                                                        disableRipple
                                                        onClick={this.handleSubmit}
                                                        id="login">
                                                        {this.state.translationFlag === true ? loginText : loginText}
                                                    </Button>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </Paper>
                        <div className="lang-chooser">
                            <Select value={this.state.selectedLanguageOption} onChange={this.languageChange}>
                                {
                                    sortedLanguages.map(lang => (
                                        <MenuItem key={lang.value} value={lang.value}>
                                            {lang.display_name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </LoginLayout>
                </React.Fragment>
            );
        } else {
            return <div>&nbsp;</div>;
        }
    }
}
